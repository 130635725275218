exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dbayconnect-js": () => import("./../../../src/pages/dbayconnect.js" /* webpackChunkName: "component---src-pages-dbayconnect-js" */),
  "component---src-pages-dictationmate-js": () => import("./../../../src/pages/dictationmate.js" /* webpackChunkName: "component---src-pages-dictationmate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mawanguide-js": () => import("./../../../src/pages/mawanguide.js" /* webpackChunkName: "component---src-pages-mawanguide-js" */),
  "component---src-pages-wuxing-2048-js": () => import("./../../../src/pages/wuxing2048.js" /* webpackChunkName: "component---src-pages-wuxing-2048-js" */)
}

